import React from "react"
import { useHistory, useLocation } from "react-router"
import TouchQR from "./ShiftActions/TouchQR"

import Shift from "interfaces/Shift"
import Worker from "interfaces/Worker"
import { InteractionMode } from "interfaces/InteractionMode"
import { DevDebugDateTime } from "./CapturePhoto"

type Props = {
    mode: InteractionMode
}

export type LocationState = {
    shift: Shift
    worker: Worker
    devDebugDateTime?: DevDebugDateTime
}

export default function ShiftActions({ mode }: Props) {
    const history = useHistory();
    const location = useLocation<LocationState>()

    if (mode === InteractionMode.TOUCH_QR) {
        return <TouchQR shift={location.state.shift} worker={location.state.worker} devDebugDateTime={location.state.devDebugDateTime}/>
    } else {
        console.error("Unknown interaction mode");
        history.goBack();
        return null;
    }
}
