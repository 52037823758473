import { instanceToTzMoment } from "components/timezone/TimezoneConversion"
import Shift from "interfaces/Shift"
import { DevDebugDateTime } from "../CapturePhoto"
import moment from "moment"

export function getDebugOrCurrentDateTime(devDebugDateTime: DevDebugDateTime | undefined) {
    if (devDebugDateTime?.date && devDebugDateTime?.time) {
        const debugIsoTime = moment
            .tz(`${devDebugDateTime.date} ${devDebugDateTime.time}`, "DD-MM-YYYY HH:mm", devDebugDateTime.timezoneId)
            .seconds(0)
            .milliseconds(0)
            .toISOString()
        // TODO: remove day and time when this is not being used anymore
        return { day: "", time: "", isoDateTime: debugIsoTime }
    }
    return {
        day: "",
        time: "",
        isoDateTime: moment.tz().seconds(0).milliseconds(0).toISOString()
    }
}

export function addMomentTimesToShift(shift: Shift, timezonesEnabled: boolean): Shift {
    return {
        ...shift,
        bookedStartTimeTz: instanceToTzMoment(
            shift.bookedStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        expectedStopTimeTz: instanceToTzMoment(
            shift.expectedStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        actStartTimeTz: instanceToTzMoment(
            shift.actStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        actStopTimeTz: instanceToTzMoment(
            shift.actStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        breakStartTimeTz: instanceToTzMoment(
            shift.breakStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        breakStopTimeTz: instanceToTzMoment(
            shift.breakStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!
    }
}
