import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import moment, { Moment } from "moment"
import LoaderButton from "../widgets/LoaderButton"
import { executeRequest, RequestType } from "../../api/APIUtils"
import endpoints from "../../api/endpoints"
import Shift from "../../interfaces/Shift"
import Worker from "../../interfaces/Worker"
import Clock from "../Clock"
import { AppContext, FeedbackType } from "../../providers/AppProvider"
import { AuthContext } from "../../providers/AuthProvider"
import { InteractionMode } from "../../interfaces/InteractionMode"
import Countdown from "../Countdown"
import ConfirmationNotice from "./ConfirmationNotice"
import ShiftInfo from "./ShiftInfo"
import TimerButton from "components/widgets/TimerButton"
import "./ShiftFound.scss"
import MessageBlock from "./MessageBlock"
import useFeatureSwitches from "hooks/useFeatureSwitches"
import StoppingShiftEarlyReasonModal from "components/StoppingShiftEarlyReasonModal"
import AttestationNoticeModal, { AttestationNotice } from "components/AttestationNoticeModal"
import requiresReasonToStopEarly from "components/reasonToStopEarly"
import { getHandlerForActionName } from "components/ShiftActions/actions"
import { getDebugOrCurrentDateTime } from "../ShiftActions/tools"
import { DevDebugDateTime } from "../CapturePhoto"

const defaultKeySuffix: string = "_cropped"
const fallbackKeySuffix: string = "_290"

interface ShiftRequest {
    id: number
    workerId: number
    day: string
    time: string
    isoDateTime?: string
    reason?: string
    attestationNotice?: string
}

interface ShiftResponse {
    success: boolean
    shift: Shift
}

interface AvatarResponse {
    success: boolean
    imageBytes: string
}

interface AvatarData {
    image?: string
    requestComplete: boolean
}

interface Props {
    shift: Shift
    worker: Worker
    devDebugDateTime?: DevDebugDateTime
    onRestart: () => void
    interactionMode: InteractionMode | undefined
    idleRestartSeconds: number
}

interface ActionLabel {
    default: string
    loading: string
}

interface ActionResult {
    handler: () => void
    label: ActionLabel
}

const actionLabels: { [key: string]: ActionLabel } = {
    START_SHIFT: {
        default: "startShift",
        loading: "startingShift"
    },
    STOP_SHIFT: {
        default: "stopShift",
        loading: "stoppingShift"
    },
    START_BREAK: {
        default: "startBreak",
        loading: "startingBreak"
    },
    STOP_BREAK: {
        default: "stopBreak",
        loading: "stoppingBreak"
    },
    NO_ACTION: {
        default: "",
        loading: "loadingAction"
    }
}

const getLabelKey = (loading: boolean): keyof ActionLabel => {
    return loading ? "loading" : "default"
}

const ShiftFound: FunctionComponent<Props> = ({ shift, worker, devDebugDateTime, onRestart, interactionMode, idleRestartSeconds }) => {
    const { setFeedback } = useContext(AppContext)
    const { operationalModeConfig, Message } = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [secondActionLoading, setSecondActionLoading] = useState<boolean>(false)
    const [isSecondAction, setIsSecondAction] = useState<boolean>(false)
    const [shiftInState, setShiftInState] = useState<Shift>(shift)
    const [isDone, setIsDone] = useState<boolean>(false)
    const [isDoneWithError, setIsDoneWithError] = useState<boolean>(false)
    const [avatarData, setAvatarData] = useState<AvatarData | null>(null)
    const [reasonToStopEarly, setReasonToStopEarly] = useState<string | undefined>(undefined)
    const [showReasonModal, setShowReasonModal] = useState<boolean>(false)
    const [showAttestationNoticeModal, setShowAttestationNoticeModal] = useState<boolean>(false)
    const [attestationNotice, setAttestationNotice] = useState<AttestationNotice | undefined>(undefined)
    const [nextActionState, setNextActionState] = useState<ActionResult>({
        handler: () => {},
        label: { default: "", loading: "" }
    })
    const [hasValidAction, setHasValidAction] = useState<boolean>(false)
    const [confirmationNotice, setConfirmationNotice] = useState<string | undefined>(undefined)
    const [actualStopTime, setActualStopTime] = useState<Moment | undefined>(undefined)

    const { loadingFeatureSwitches, timezonesEnabled, earlyStopReasonEnabled } = useFeatureSwitches()

    const resetCountdown = operationalModeConfig?.resetCountdown || 5
    
    useEffect(() => {
        if (!loadingFeatureSwitches) {
            setLoading(false)
            setShiftInState(shift)
            setNextActionState(getNextAvailableAction(shift.action))
        }
    }, [shift, earlyStopReasonEnabled, loadingFeatureSwitches])

    useEffect(() => {
        if (!worker.avatarPath) {
            setAvatarData({
                requestComplete: true
            })
            return
        }

        const getAvatar = async () => {
            try {
                let avatarResponse: AvatarResponse = await executeGetAvatarRequest(defaultKeySuffix)
                if (!avatarResponse || !avatarResponse.success) {
                    avatarResponse = await executeGetAvatarRequest(fallbackKeySuffix)
                }

                if (avatarResponse && avatarResponse.success) {
                    setAvatarData({
                        image: "data:image/png;base64," + avatarResponse.imageBytes,
                        requestComplete: true
                    })
                } else {
                    throw new Error()
                }
            } catch (_) {
                setAvatarData({ requestComplete: true })
            }
        }
        getAvatar()
    }, [worker])

    const executeGetAvatarRequest = (keySuffix: string): Promise<AvatarResponse> => {
        try {
            return executeRequest({
                endpoint: `${endpoints.worker.GET_AVATAR}?key=${worker.avatarPath + keySuffix}`,
                withApiKey: true,
                requestType: RequestType.GET
            })
        } catch (error: any) {
            console.error(error)
            return new Promise<AvatarResponse>(resolve => {
                resolve({ success: false, imageBytes: "" })
            })
        }
    }

    const isTouch =
        operationalModeConfig &&
        [InteractionMode.TOUCH, InteractionMode.TOUCH_QR].includes(operationalModeConfig.interactionMode)

    // TODO: use actions defined in ShiftActions/actions.ts

    const startShift = async () => {
        setHasValidAction(false)

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)
        const startShiftRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...getDebugOrCurrentDateTime(devDebugDateTime)
        };
        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.START_SHIFT,
                withApiKey: true,
                requestType: RequestType.POST,
                params: startShiftRequest
            })
            if (success) {
                    setShiftInState({ 
                        ...shiftInState, 
                        actStartTime: shift.actStartTime 
                    })
                setIsDone(true)
                setConfirmationNotice(Message("shiftStartSubmittedSuccessfully"))
            } else {
                throw new Error()
            }
        } catch (error: any) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }

    const startBreak = async () => {
        setHasValidAction(false)
        setShiftInState({ ...shiftInState, canStopShift: false })

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)

        const startBreakRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...getDebugOrCurrentDateTime(devDebugDateTime)
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.START_BREAK,
                withApiKey: true,
                requestType: RequestType.POST,
                params: startBreakRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    breakStartTime: shift.breakStartTime
                })
                setIsDone(true)
                setConfirmationNotice(Message("shiftBreakStartSubmittedSuccessfully"))
            } else {
                throw new Error()
            }
        } catch (error: any) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }

    const stopBreak = async () => {
        setHasValidAction(false)

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)

        const stopBreakRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...getDebugOrCurrentDateTime(devDebugDateTime)
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.STOP_BREAK,
                withApiKey: true,
                requestType: RequestType.POST,
                params: stopBreakRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    breakStopTime: shift.breakStopTime
                })
                setIsDone(true)
                setConfirmationNotice(Message("shiftBreakStopSubmittedSuccessfully"))
            } else {
                throw new Error()
            }
        } catch (error: any) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }

    const stopShift = async (reasonParam: string | undefined, attestationParam: AttestationNotice | undefined, ) => {
       
        if (devDebugDateTime !== undefined){
            setActualStopTime(moment(devDebugDateTime.date + " " + devDebugDateTime.time))
        }
       
        if (!actualStopTime) {
            setActualStopTime(moment())
        }
        
        if (shiftInState.action === "START_BREAK") {
            setIsSecondAction(true)
            setNextActionState(getNextAvailableAction("STOP_SHIFT"))
        }
        setHasValidAction(false)
        
        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        if (shiftInState.enableAttestationNotice && isTouch && !attestationParam && !attestationNotice && !showReasonModal) {
            setShowAttestationNoticeModal(true)
            return
        }
        
        if (requiresReasonToStopEarly(getHandlerForActionName("STOP_SHIFT"), shift, earlyStopReasonEnabled) && !reasonParam && !reasonToStopEarly) {
            setShowReasonModal(true)
            return
        }

        const stopShiftRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...getDebugOrCurrentDateTime(devDebugDateTime),
            reason: reasonToStopEarly || reasonParam,
            attestationNotice: attestationNotice || attestationParam
        }

        setLoading(true)
        setSecondActionLoading(true)

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.STOP_SHIFT,
                withApiKey: true,
                requestType: RequestType.POST,
                params: stopShiftRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    actStopTime: shift.actStopTime
                })
                setIsDone(true)
                setShowReasonModal(false)
                setShowAttestationNoticeModal(false)
                setAttestationNotice(undefined)
                setReasonToStopEarly(undefined)
                setConfirmationNotice(Message("shiftStopSubmittedSuccessfully"))
            } else {
                throw new Error()
            }
        } catch (error: any) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
            setSecondActionLoading(false)
        }
    }

    const outputAvatar = () => {
        if (!avatarData) {
            return <div className="loader" />
        }
        if (avatarData.image) {
            return <img src={avatarData.image} alt="" />
        }
    }

    const getNextAvailableAction = (action: string): ActionResult => {
        if (interactionMode !== InteractionMode.TOUCH && (action === "START_BREAK" || action === "STOP_BREAK")) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: Message("contactlessWithBreaks")
            })
            return { handler: () => {}, label: actionLabels.NO_ACTION }
        }

        setHasValidAction(true)

        if (action === "START_SHIFT") {
            return { handler: startShift, label: actionLabels.START_SHIFT }
        } else if (action === "START_BREAK") {
            return { handler: startBreak, label: actionLabels.START_BREAK }
        } else if (action === "STOP_BREAK") {
            return { handler: stopBreak, label: actionLabels.STOP_BREAK }
        } else if (action === "STOP_SHIFT") {
            return { handler: () => stopShift(reasonToStopEarly, attestationNotice), label: actionLabels.STOP_SHIFT }
        }
        return { handler: () => {}, label: actionLabels.NO_ACTION }
    }

    if (isDone && interactionMode === InteractionMode.TOUCH && confirmationNotice) {
        return (
            <ConfirmationNotice
                message={confirmationNotice}
                shift={shiftInState}
                timezonesEnabled={timezonesEnabled}
                onRestart={onRestart}
                autoRestartMs={idleRestartSeconds * 1000}
            />
        )
    }

    const showSecondaryAction =
        shiftInState.action === "START_BREAK" && shiftInState.canStopShift && interactionMode === InteractionMode.TOUCH

    return (
        <div className="shiftFound contentBox">
            {showReasonModal && (
                <StoppingShiftEarlyReasonModal
                    onConfirmReason={
                        reason => {
                            setShowReasonModal(false)
                            setReasonToStopEarly(reason)
                            stopShift(reason, attestationNotice)
                        }
                    }
                    loading={loading}
                />
            )}

            {showAttestationNoticeModal && (
                <AttestationNoticeModal
                    onConfirmAttestationNotice={
                        attestationNoticeValue => {
                            setShowAttestationNoticeModal(false)
                            setAttestationNotice(attestationNoticeValue)
                            stopShift(reasonToStopEarly, attestationNoticeValue)
                        }
                    }
                    actualStopTime={actualStopTime || moment()}
                    shift={shiftInState}
                    loading={loading}
                />
            )}

            {interactionMode !== InteractionMode.QR && (
                <div className="field textAlignCenter">
                    <div className="workerPhoto">{outputAvatar()}</div>
                </div>
            )}

            <MessageBlock shift={shiftInState} worker={worker} isDone={isDone} />

            <ShiftInfo shift={shiftInState} timezonesEnabled={timezonesEnabled} />

            {!isDone && !shift.actStopTime && (
                <div className="startOrStopShift">
                    <div className="time">
                        <div>{Message("timeNow")}</div>
                        <div className="larger">
                            <Clock
                                toTz={shiftInState.siteTimezoneId || shiftInState.instanceTimezoneId}
                                timezonesEnabled={timezonesEnabled}
                            />
                        </div>
                    </div>
                    <div className="action">
                        {interactionMode !== InteractionMode.TOUCH ? (
                            hasValidAction ? (
                                // FIXME: strange use of initialCountdownSeconds={0} - it will call onCountdownComplete immediately
                                <Countdown
                                    initialCountdownSeconds={0}
                                    displayedMessage="stepAside"
                                    onCountdownComplete={nextActionState.handler}
                                />
                            ) : (
                                !isDoneWithError && (
                                    <LoaderButton
                                        onClick={nextActionState.handler}
                                        loading={loading}
                                        className="bigger"
                                    >
                                        {Message(nextActionState.label[getLabelKey(loading)])}
                                    </LoaderButton>
                                )
                            )
                        ) : (
                            !isSecondAction && (
                                <TimerButton
                                    onClick={nextActionState.handler}
                                    loading={loading}
                                    autoClickInMs={showSecondaryAction || showAttestationNoticeModal || showReasonModal ? undefined : idleRestartSeconds * 1000}
                                    text={Message(nextActionState.label[getLabelKey(loading)])}
                                />
                            )
                        )}
                    </div>
                </div>
            )}

            {showSecondaryAction && (
                <div className="secondaryAction">
                    <LoaderButton onClick={() => stopShift(reasonToStopEarly, attestationNotice)} loading={secondActionLoading} className="fullWidth bigger">
                        {Message(actionLabels.STOP_SHIFT[getLabelKey(secondActionLoading)])}
                    </LoaderButton>
                </div>
            )}

            {interactionMode !== InteractionMode.TOUCH && (isDone || isDoneWithError) && (
                <div className={`bottomBar ${isDone ? "textAlignCenter" : ""}`}>
                    <div className="contentBox">
                        <Countdown
                            initialCountdownSeconds={resetCountdown}
                            displayedMessage={isDone ? "stepAside" : "tryAgain"}
                            onCountdownComplete={onRestart}
                        />
                    </div>
                </div>
            )}

            {interactionMode === InteractionMode.TOUCH && (
                <div className="bottomBar">
                    <div className="contentBox">
                        <button className="grey bigger" onClick={onRestart}>
                            {Message("back")}
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
export default ShiftFound
